/**
 * Turns query string like hello=1&another=2 into object {hello: 1, another: 2}
 * @param {*} queryString
 */
function parseQuery(queryString) {
  var query = {};
  var pairs = (
    queryString[0] === "?" ? queryString.substring(1) : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

function modifyColor(col, amt) {
  var usePound = false;

  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (
    (usePound ? "#" : "") +
    String("000000" + (g | (b << 8) | (r << 16)).toString(16)).slice(-6)
  );
}

function addAlpha(color, opacity) {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

function parsePrice(price, thousandSeparator, decimalSeparator) {
  const regEx = new RegExp(
    `([0-9${thousandSeparator}]+)(\\${decimalSeparator}\\d+)?`
  );
  const [_ignored, int, decimals] = price.match(regEx);
  return parseFloat(
    `${int.replace(/[^0-9]/g, "")}.${(decimals || "0").replace(/[^0-9]/g, "")}`
  );
}

function formatPrice(price) {
  return `NOK ${price}.-`;
}

function translateBreadcrumbsUrl(breadcrumbs, originalUrl, translatedUrl) {
  const updatedBreadcrumbs = breadcrumbs.map((breadcrumb) => {
    const { text, url } = breadcrumb;
    // Use the replace method to substitute "treatment" with "behandlinger" in the URL.
    // NOTE: the post type should be written with escape character for slashes, so for example treatment --> /\/treatment\//
    const updatedUrl = url.replace(originalUrl, translatedUrl);
    return { text, url: updatedUrl };
  });

  return updatedBreadcrumbs;
}

// This is se to get the name of the Font from the URL
function modifyFileName(url) {
  const fileNameWithFormat = url.substring(url.lastIndexOf("/") + 1);
  const fileNameWithoutFormat = fileNameWithFormat.substring(
    0,
    fileNameWithFormat.lastIndexOf(".")
  );
  const modifiedFileName = fileNameWithoutFormat.replace(/-/g, " ");
  return modifiedFileName;
}

function generate_uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

function checkGTM() {
  const scripts = document.head.getElementsByTagName("script");
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src.includes("www.googletagmanager.com")) {
      return true;
    }
  }
  return false;
}

function updateDataLayer(datalayerFunction) {
  const interval = setInterval(async () => {
    if (checkGTM()) {
      window.dataLayer = window.dataLayer ?? [];
      window.dataLayer.push({ ecommerce: null });
      await datalayerFunction();
      clearInterval(interval);
    }
  }, 200);
}

module.exports = {
  parseQuery,
  addAlpha,
  modifyColor,
  parsePrice,
  formatPrice,
  translateBreadcrumbsUrl,
  modifyFileName,
  generate_uuidv4,
  updateDataLayer,
};
