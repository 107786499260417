import React from "react";
import GlobalContextProvider from "./src/context/global-context-provider";
import { store, persistor } from "./src/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import "./src/style/global.css";

export const wrapRootElement = ({ element }) => {
  return (
    <GlobalContextProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {() => <>{element}</>}
        </PersistGate>
      </Provider>
    </GlobalContextProvider>
  );
};
