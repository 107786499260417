import { createSlice } from "@reduxjs/toolkit";
import { generate_uuidv4 } from "../../utils/helpers";

const defaultStyle = {
  officialStyleName: "",
  styleNameToDisplay: "",
};

const defaultSize = {
  name: "",
  price: 0,
  sizeWidth: 0,
  sizeHeight: 0,
};

const defaultPaspatur = {
  name: "",
  price: 0,
  paspaturColor: "#ffffff",
  paspaturSize: "",
};

const defaultFrame = {
  name: "",
  price: 0,
  frameColor: "#ffffff",
  frameSize: "",
  frameBorder: "",
};

const defaultTheme = {
  name: "",
  themeUrl: "",
  themeAlt: " ",
};

const defaultFontColor = {
  name: "",
  fontColor: "#525D4D",
};

const defaultBackgroundColor = {
  name: "",
  backgroundColor: "#b1d0b7",
};

const initialState = {
  step: 1,
  picture_uuid: "",
  orientation: "portrett",
  letter: "",
  textBelow: "",
  textAbove: "",
  fontColor: defaultFontColor,
  backgroundColor: defaultBackgroundColor,
  style: defaultStyle,
  theme: defaultTheme,
  size: defaultSize,
  paspatur: defaultPaspatur,
  frame: defaultFrame,
  designStudioVersion: process.env.DESIGN_STUDIO_VERSION ?? "1",
  options: {
    sizes: [],
    frames: [],
    paspaturs: [],
    fontColors: [],
    backgroundCOlors: [],
    style: [],
    themes: [],
    letters: [],
  },
};

export const designStudioSlice = createSlice({
  name: "designStudio",
  initialState,
  reducers: {
    updateDesignStudioStep: (state, action) => {
      state.step = action.payload;
    },
    updateDesignStudioOrientation: (state, action) => {
      state.orientation = action.payload;

      state.paspatur = state.options.paspaturs.find(
        (item) => item.name === "Ingen" || item.price === "0"
      );
      state.frame = state.options.frames.find(
        (item) => item.name === "Ingen" || item.price === "0"
      );

      if (action.payload === "landskap") {
        state.letter = "";
        if (
          parseInt(state.size.sizeWidth) === 0 ||
          parseInt(state.size.sizeWidth) < parseInt(state.size.sizeHeight)
        ) {
          state.size = state.options.sizes.find(
            (item) => item.sizeWidth > item.sizeHeight
          );
        }
      } else {
        if (
          parseInt(state.size.sizeWidth) === 0 ||
          parseInt(state.size.sizeWidth) > parseInt(state.size.sizeHeight)
        ) {
          state.size = state.options.sizes.find(
            (item) => item.sizeWidth < item.sizeHeight
          );
        }

        if (state.orientation === "portrett") {
          state.letter = "";
        } else {
          state.letter = state.options.letters[0];
        }
      }
    },
    updateDesignStudioTextAbove: (state, action) => {
      state.textAbove = action.payload;
    },
    updateDesignStudioTextBelow: (state, action) => {
      state.textBelow = action.payload;
    },
    updateDesignStudioLetter: (state, action) => {
      state.letter = action.payload;
    },
    updateDesignStudioStyle: (state, action) => {
      state.style = action.payload;
    },
    updateDesignStudioFontColor: (state, action) => {
      state.fontColor = action.payload;
    },
    updateDesignStudioBackgroundColor: (state, action) => {
      state.backgroundColor = action.payload;
    },
    updateDesignStudioTheme: (state, action) => {
      state.theme = action.payload;
    },
    updateDesignStudioSize: (state, action) => {
      state.size = action.payload;

      const findOption = (options, propertyName, actionSize) => {
        const namePart = propertyName.split("-")?.[0];
        return (
          options.find(
            (option) =>
              (option.name === propertyName && !option[actionSize]) ||
              (option.name.split("-")?.[0] === namePart &&
                option[actionSize] === action.payload.name)
          ) ||
          options.find(
            (option) => option.name === "Ingen" || option.price === "0"
          ) ||
          options[0]
        );
      };

      state.frame = findOption(
        state.options.frames,
        state.frame.name,
        "frameSize"
      );
      state.paspatur = findOption(
        state.options.paspaturs,
        state.paspatur.name,
        "paspaturSize"
      );
    },
    updateDesignStudioPaspatur: (state, action) => {
      state.paspatur = action.payload;
    },
    updateDesignStudioFrame: (state, action) => {
      state.frame = action.payload;
    },
    updateDesignStudioOptions: (state, action) => {
      state.options = action.payload;
    },
    clearUserChoices: (state) => {
      state.step = 1;
      state.letter = "";
      state.picture_uuid = generate_uuidv4();
      state.orientation = "portrett";
      state.textBelow = "";
      state.textAbove = "";
      state.fontColor = state.options.fontColors?.[0];
      state.backgroundColor = state.options.backgroundColors?.[0];
      state.style = state.options.styles[0];
      state.theme = state.options.themes?.[0];
      state.size = state.options.sizes.find((size) => size.name === "30x40");
      state.paspatur = state.options.paspaturs.find(
        (item) => item.name === "Ingen" || item.price === "0"
      );
      state.frame = state.options.frames.find(
        (item) => item.name === "Ingen" || item.price === "0"
      );
    },
    restoreDesignStudio: (state, action) => {
      state.picture_uuid = action.payload.picture_uuid;
      state.step = 1;
      state.letter = action.payload.letter ?? "A";
      state.orientation = action.payload.orientation ?? "portrett";
      state.textBelow = action.payload.textBelow ?? "";
      state.textAbove = action.payload.textAbove ?? "";
      state.fontColor =
        action.payload.fontColor ?? state.options.fontColors?.[0];
      state.backgroundColor =
        action.payload.backgroundColor ?? state.options.backgroundColors?.[0];
      state.style = action.payload.style ?? state.options.styles[0];
      state.theme = action.payload.theme ?? state.options.themes?.[0];
      state.size =
        action.payload.size ??
        state.options.sizes.find((size) => size.name === "30x40");
      state.paspatur =
        action.payload.paspatur ??
        state.options.paspaturs.find(
          (item) => item.name === "Ingen" || item.price === "0"
        );
      state.frame =
        action.payload.frame ??
        state.options.frames.find(
          (item) => item.name === "Ingen" || item.price === "0"
        );
    },
    updateDesignStudioVersion: (state) => {
      state.designStudioVersion = process.env.DESIGN_STUDIO_VERSION ?? "1";
    },
  },
});

export const {
  updateDesignStudioFontColor,
  updateDesignStudioBackgroundColor,
  updateDesignStudioOrientation,
  updateDesignStudioFrame,
  updateDesignStudioPaspatur,
  updateDesignStudioSize,
  updateDesignStudioStep,
  updateDesignStudioStyle,
  updateDesignStudioTextAbove,
  updateDesignStudioTextBelow,
  updateDesignStudioLetter,
  updateDesignStudioTheme,
  updateDesignStudioOptions,
  clearUserChoices,
  restoreDesignStudio,
  updateDesignStudioVersion,
} = designStudioSlice.actions;
export default designStudioSlice.reducer;
