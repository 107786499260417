import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { PERSIST, persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import designStudioReducer from "./reducers/design-studio-reducer";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

const createNoopStorage = () => {
  return {
    getItem() {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem() {
      return Promise.resolve();
    },
  };
};
const storage =
  typeof window !== "undefined"
    ? createWebStorage("local")
    : createNoopStorage();

const reducers = combineReducers({
  designStudio: designStudioReducer,
});

const persistConfig = {
  key: "root",
  version: process.env.DESIGN_STUDIO_VERSION ?? "1",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST],
      },
    }),
});

export const persistor = persistStore(store);
